import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1599.000000 1600.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)">
          <path d="M7480 12585 l0 -145 -145 0 -145 0 0 -1661 c0 -1578 1 -1661 18
-1654 149 62 915 303 1287 406 83 23 158 44 168 46 16 5 17 89 17 1579 l0
1574 -600 0 -600 0 0 -145z"/>
          <path d="M10753 12718 c-4 -7 -38 -71 -75 -143 l-66 -130 -73 -5 -73 -5 -204
-405 c-512 -1017 -1170 -2335 -1167 -2339 2 -2 68 11 147 29 363 81 680 139
973 180 83 11 182 25 220 30 39 5 126 14 195 20 199 17 182 9 228 108 23 48
190 389 373 757 747 1508 939 1899 939 1907 0 4 -317 8 -704 8 -553 0 -707 -3
-713 -12z"/>
          <path d="M5346 11319 c-163 -17 -277 -48 -596 -162 -200 -71 -276 -103 -430
-184 -253 -132 -452 -263 -1025 -674 -283 -203 -538 -376 -632 -430 l-43 -24
0 -842 0 -843 503 -1 c276 -1 643 -4 815 -8 l312 -6 0 48 c0 53 3 51 -85 38
-33 -5 -86 -12 -117 -15 -53 -6 -58 -5 -58 14 0 19 6 20 130 20 l130 0 0 559
c0 523 1 559 18 564 434 135 618 170 892 171 230 1 287 -9 660 -119 167 -49
856 -278 893 -297 16 -8 17 -1 17 86 l0 95 103 -35 c56 -19 114 -39 130 -44
l27 -9 0 762 0 762 -105 68 c-619 402 -1083 554 -1539 506z"/>
          <path d="M10517 9376 c-4 -110 -7 -240 -7 -287 l0 -86 267 -6 267 -6 57 52
c31 29 59 58 62 65 5 9 -32 12 -166 12 -95 0 -202 3 -239 7 l-68 6 0 50 0 50
153 -5 153 -5 64 59 65 58 -218 0 -217 0 0 54 0 54 193 -5 192 -5 65 60 c36
33 63 62 60 65 -3 2 -156 7 -341 10 l-336 5 -6 -202z"/>
          <path d="M11404 9528 c-114 -92 -102 -68 -120 -242 l-15 -156 57 -70 c31 -38
57 -70 58 -70 0 0 57 -6 126 -14 69 -8 161 -17 205 -21 l80 -6 67 54 66 55
-91 11 c-51 6 -137 16 -192 22 -55 6 -124 13 -154 16 l-53 6 5 36 c3 20 11 91
18 158 l13 122 45 -5 c25 -3 80 -9 121 -14 41 -5 108 -12 147 -15 l73 -7 62
52 c35 29 64 55 66 59 1 4 -57 13 -130 19 -73 7 -171 17 -218 22 -205 23 -191
23 -236 -12z"/>
          <path d="M10198 9541 c-26 -4 -50 -10 -54 -14 -4 -4 2 -78 14 -164 12 -86 20
-158 18 -161 -3 -2 -96 52 -208 120 -261 161 -236 149 -308 141 -59 -6 -61 -7
-56 -32 3 -14 19 -127 36 -251 17 -124 36 -263 43 -308 l12 -84 78 59 78 58
-5 45 c-3 25 -12 98 -21 164 -9 65 -15 120 -13 121 2 2 102 -57 222 -132 l219
-135 61 7 c34 4 64 9 67 11 3 2 2 27 -2 56 -5 29 -16 105 -25 168 -8 63 -22
160 -29 215 -8 55 -15 106 -15 113 0 13 -33 14 -112 3z"/>
          <path d="M12196 9433 c21 -37 42 -74 48 -80 6 -7 46 -23 88 -35 l76 -23 -40
-130 c-21 -72 -57 -188 -79 -258 -23 -71 -39 -130 -37 -131 2 -2 45 6 95 18
59 13 95 26 99 36 3 8 32 102 65 209 33 107 61 197 64 199 2 2 34 -5 71 -17
37 -12 76 -21 86 -21 25 0 160 73 150 81 -5 4 -530 165 -705 215 -14 4 -10 -8
19 -63z"/>
          <path d="M9355 9423 c-43 -8 -80 -15 -82 -16 -1 -2 12 -73 30 -159 17 -87 28
-160 25 -163 -6 -7 -55 18 -324 166 l-141 77 -59 -12 c-41 -9 -58 -17 -57 -27
3 -20 60 -320 93 -487 16 -79 31 -146 34 -149 7 -7 146 116 146 128 0 5 -14
78 -30 161 -17 83 -29 152 -28 154 2 1 107 -53 233 -121 l230 -123 63 10 c34
6 62 14 62 18 0 5 -16 88 -35 187 -19 98 -43 219 -52 268 -9 50 -19 93 -22 96
-3 3 -42 0 -86 -8z"/>
          <path d="M10895 9390 c88 -4 164 -4 169 0 4 5 -68 8 -160 8 l-169 -1 160 -7z" />
          <path d="M8233 9181 c-122 -31 -224 -59 -226 -62 -2 -2 -24 -38 -49 -80 l-46
-76 29 -114 c51 -202 41 -182 127 -235 89 -54 47 -56 360 23 l182 46 48 79 49
80 -38 150 c-21 83 -39 151 -41 153 -14 13 -157 95 -164 94 -5 -1 -109 -27
-231 -58z m268 -231 c20 -80 34 -148 30 -152 -6 -6 -306 -83 -351 -90 -23 -3
-25 1 -61 147 -20 83 -35 152 -33 154 6 7 349 90 364 88 10 -2 26 -47 51 -147z"/>
          <path d="M10793 9173 c37 -2 96 -2 130 0 34 2 4 3 -68 3 -71 0 -99 -1 -62 -3z" />
          <path d="M7720 9053 c-36 -12 -152 -50 -258 -84 l-194 -62 -41 -83 -41 -83 38
-118 c21 -65 42 -132 48 -149 9 -25 26 -39 95 -72 l83 -42 173 56 c94 30 181
59 193 63 20 9 100 148 90 158 -3 3 -103 -27 -224 -66 -120 -39 -223 -71 -228
-71 -5 0 -28 60 -51 133 -23 72 -44 139 -48 148 -5 14 24 26 177 76 l183 59
38 72 c21 40 37 76 35 80 -2 4 -32 -2 -68 -15z"/>
          <path d="M10635 8784 c-808 -59 -1684 -257 -2815 -634 -274 -92 -371 -125
-700 -243 -161 -58 -814 -303 -1085 -408 -115 -44 -273 -105 -350 -134 -77
-30 -223 -86 -325 -125 -253 -98 -1179 -444 -1375 -514 -185 -67 -854 -291
-1000 -335 -691 -209 -1197 -334 -1700 -421 -302 -52 -653 -95 -862 -106 -141
-8 -134 -1 -102 -106 l20 -69 37 5 c20 3 91 10 157 16 131 12 175 17 430 55
489 73 962 180 1572 354 40 12 75 21 78 21 3 0 5 -193 5 -430 l0 -429 43 -24
c23 -14 119 -67 212 -119 94 -53 373 -209 620 -348 1180 -663 1354 -756 1515
-808 174 -56 246 -67 450 -66 213 1 307 17 500 85 63 22 166 58 227 80 251 86
453 196 700 378 l103 76 0 628 c0 345 -2 627 -4 627 -2 0 -59 -27 -127 -60
-96 -46 -125 -57 -127 -45 -2 8 -4 15 -6 15 -1 0 -51 -24 -112 -53 -148 -73
-226 -103 -328 -127 -133 -31 -268 -43 -399 -36 -280 15 -518 81 -1057 291
-151 59 -342 131 -425 159 l-150 51 0 353 0 354 325 122 c412 156 591 224
1330 509 479 184 1018 386 1197 447 l83 29 2 -2622 3 -2621 140 168 c119 143
141 165 145 146 4 -19 53 35 295 326 160 192 428 515 598 719 l307 369 0 694
0 693 532 1061 531 1062 36 -75 c20 -40 78 -162 128 -269 175 -371 191 -406
257 -544 36 -76 66 -140 66 -141 0 -1 32 -69 71 -151 39 -82 84 -176 99 -209
15 -33 58 -122 94 -199 l66 -138 0 -1034 0 -1034 600 0 599 0 3 143 3 142 143
3 142 3 0 1213 0 1213 -66 139 c-36 77 -79 166 -94 199 -15 33 -103 220 -195
415 -92 195 -187 396 -210 445 -23 50 -64 135 -90 190 -26 55 -62 132 -80 170
-18 39 -61 128 -94 199 -34 71 -61 133 -61 139 0 12 203 3 380 -18 1110 -133
1988 -611 2731 -1487 108 -128 274 -346 344 -450 21 -32 40 -58 42 -58 3 0 2
75 -2 168 -8 193 -2 177 -128 338 -309 397 -649 724 -1014 977 -513 355 -1081
574 -1733 667 -336 48 -773 61 -1145 34z"/>
          <path d="M5815 8319 c-104 -43 -197 -80 -205 -83 -8 -3 -63 -24 -122 -48
l-107 -44 103 -255 c57 -141 106 -261 110 -267 4 -6 98 27 254 90 l249 100 31
76 c17 42 29 78 27 80 -2 3 -101 -34 -218 -82 -118 -47 -219 -86 -223 -86 -5
0 -16 20 -25 44 l-16 45 145 58 146 58 28 69 c15 38 26 71 23 74 -3 3 -17 0
-32 -7 -117 -53 -347 -141 -354 -134 -4 4 -15 24 -23 44 l-15 36 37 16 c78 34
278 115 298 121 15 5 31 30 53 82 18 42 31 79 29 83 -2 4 -88 -28 -193 -70z"/>
          <path d="M5940 8180 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
          <path d="M5842 8143 c-35 -14 -61 -28 -58 -30 2 -3 26 5 51 16 26 12 53 21 61
21 7 0 16 5 19 10 9 15 -3 12 -73 -17z"/>
          <path d="M4990 7983 c-168 -69 -324 -131 -348 -140 -24 -8 -40 -19 -35 -24 4
-4 40 -21 79 -38 l71 -30 72 29 c40 17 76 30 80 30 4 0 52 -110 106 -245 54
-135 102 -245 105 -245 6 0 97 111 113 137 4 7 -29 103 -73 213 -45 111 -79
205 -75 208 3 4 38 21 78 37 l71 30 34 79 c18 43 32 79 30 80 -2 2 -140 -53
-308 -121z"/>
          <path d="M5703 8088 c-29 -11 -53 -24 -53 -29 0 -7 107 35 119 47 9 9 -16 2
-66 -18z"/>
          <path d="M5345 8080 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
          <path d="M5910 7934 l-25 -15 25 7 c14 3 29 10 35 15 15 14 -7 10 -35 -7z" />
          <path d="M5808 7896 c-32 -12 -63 -27 -69 -33 -7 -7 20 0 59 16 67 26 97 42
76 40 -5 0 -35 -10 -66 -23z"/>
          <path d="M4455 7774 c-16 -7 -52 -23 -78 -34 -28 -12 -47 -26 -44 -33 3 -7 39
-91 80 -187 41 -96 96 -228 123 -292 27 -65 52 -118 56 -118 4 0 33 34 64 75
l56 74 -107 253 c-59 139 -110 258 -114 264 -4 8 -14 8 -36 -2z"/>
          <path d="M3730 7514 c-30 -41 -54 -80 -52 -87 4 -13 213 -510 218 -515 1 -2
60 21 131 51 70 30 180 76 243 102 63 27 116 49 118 50 2 2 72 167 72 171 0 1
-93 -38 -207 -87 -115 -49 -217 -92 -228 -95 -19 -5 -32 21 -125 240 -57 136
-107 246 -110 246 -3 0 -30 -34 -60 -76z"/>
          <path d="M3510 7380 c-36 -16 -173 -75 -305 -131 -132 -56 -241 -102 -243
-104 -2 -1 47 -120 109 -264 103 -242 113 -261 133 -255 11 4 123 50 248 103
l226 96 32 78 c18 43 30 80 29 82 -2 2 -103 -39 -224 -90 l-220 -92 -17 36
c-10 20 -18 41 -18 47 0 6 63 38 141 71 l141 60 29 72 c34 85 34 84 12 72 -37
-19 -358 -151 -369 -151 -11 0 -49 79 -42 85 2 1 81 35 177 76 160 67 175 76
188 106 37 91 52 133 45 132 -4 0 -36 -14 -72 -29z"/>
          <path d="M3631 7384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M4480 7215 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
          <path d="M3400 7144 c-58 -25 -120 -52 -139 -60 -19 -8 -32 -16 -30 -19 5 -4
278 111 288 121 12 12 -24 -1 -119 -42z"/>
          <path d="M3490 6944 l-25 -15 25 7 c14 3 29 10 35 15 15 14 -7 10 -35 -7z" />
          <path d="M3420 6914 l-25 -15 25 7 c14 3 29 10 35 15 15 14 -7 10 -35 -7z" />
          <path d="M3341 6884 c-19 -8 -32 -17 -29 -19 5 -6 78 22 78 30 0 8 -13 5 -49
-11z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
